import { Mapper } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialMapperStep from './MaterialMapperStep/MaterialMapperStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
import { MapperElement } from '../../@Types/MapperElement';
interface CustomMapperElementModifiers<Type> {
    calcName?: (element: MapperElement<Type>) => string;
}
export interface MapperStepProps<Type> extends StepProps {
    /** The MapperStep to display */
    step: Mapper;
    customAdd?: (
        ref: React.RefObject<HTMLDivElement>,
        disabled: boolean,
        handleAddElement: (value?: any) => void
    ) => JSX.Element;
    customElementRender?: (
        element: MapperElement<Type>,
        index: number,
        renderElement: (
            renderElementButtons?: (element: MapperElement<any>) => JSX.Element
        ) => JSX.Element
    ) => JSX.Element;
    customElementModifiers?: CustomMapperElementModifiers<Type>;
    calcCustomStepProps?: (
        element: MapperElement<Type>,
        customStepProps: Record<string, any>
    ) => Record<string, any>;
}
function MapperStep<Type>(props: MapperStepProps<Type>): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);
    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialMapperStep {...props} />;
        }
    }
}
export default MapperStep;
