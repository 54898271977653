import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import styles from './ConfirmationDialog.module.css';
import Dialog from '@material-ui/core/Dialog';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useAppSelector } from '../../hooks';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useContext, useMemo } from 'react';
import { mapDraftEntities } from '../../Utils/DraftFunctions';
import FormContext from '../../Contexts/FormContext';
import { AppProps } from '../../App/App';
interface ConfirmationDialogProps {
    confirmation: { case: string; url: string };
    customConfirmation?: AppProps['customConfirmation'];
    onClose: () => void;
}

function ConfirmationDialog({
    onClose,
    confirmation,
    customConfirmation,
}: ConfirmationDialogProps): JSX.Element {
    const { formStyle, ...global } = useAppSelector((state) => state.global);

    const form = useContext(FormContext);
    const dependencies = useAppSelector((state) => state.site.dependencies);
    const widthStats = useAppSelector((state) => state.widthStats);

    const editorState = useMemo(() => {
        return EditorState.createWithContent(
            convertFromRaw(
                mapDraftEntities(
                    {
                        form,
                        dependencies,
                        ticket: { caseNumber: confirmation.case },
                    },
                    global.confirmation.confirmationMessage
                )
            )
        );
    }, [global.confirmation, dependencies, form]);

    const hasText = editorState.getCurrentContent().hasText();

    const renderIcon = (): JSX.Element => {
        return (
            <div
                className={styles.checkContainer}
                style={{
                    color: formStyle.primaryColor,
                    paddingTop: widthStats.isMobile ? '20px' : '30px',
                }}
            >
                <CheckCircleOutlineRoundedIcon
                    fontSize="inherit"
                    style={{
                        fontSize: widthStats.isMobile ? '100px' : '160px',
                    }}
                />
            </div>
        );
    };

    const renderConfirmation = (
        state: EditorState = editorState
    ): JSX.Element | void => {
        if (hasText) {
            return (
                <div
                    className={styles.messageContainer}
                    style={{
                        fontSize: widthStats.isMobile ? '1.1rem' : '1.4rem',
                        padding: widthStats.isMobile ? '5px 0px' : '20px 0px',
                    }}
                >
                    <Editor
                        editorClassName={'Erk-Forms-Confirmation'}
                        editorState={state}
                        readOnly
                        toolbarHidden
                    />
                </div>
            );
        }
    };

    const renderLink = (): JSX.Element | void => {
        if (global.confirmation.showLink) {
            return (
                <>
                    <div
                        className={
                            widthStats.isMobile
                                ? styles.mobileLinkContainer
                                : styles.linkContainer
                        }
                    >
                        Puedes consultar el estado aqui:
                    </div>
                    <a
                        className={styles.url}
                        data-testid="ResUrl"
                        target="_blank"
                        href={confirmation.url}
                        style={{
                            color: '#293241',
                            fontSize: widthStats.isMobile ? '1rem' : '1.2rem',
                        }}
                    >
                        {confirmation.url}
                    </a>
                </>
            );
        }
    };

    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    maxWidth: '100vw',
                    boxSizing: 'content-box',
                },
            }}
            open={true}
        >
            <div
                className={styles.confirmationContainer}
                style={{
                    color: '#293241',
                    padding: widthStats.isMobile ? '0px 20px' : '0px 30px',
                }}
            >
                <div
                    className={styles.closeIcon}
                    onClick={(): void => {
                        onClose();
                    }}
                >
                    <CloseRoundedIcon fontSize="inherit" />
                </div>
                {!customConfirmation && (
                    <div className={styles.container}>
                        {renderIcon()}
                        {renderConfirmation()}
                        {renderLink()}
                    </div>
                )}
                {customConfirmation?.(
                    {
                        url: confirmation.url,
                        caseNumber: confirmation.case,
                    },
                    renderIcon,
                    renderConfirmation,
                    renderLink,
                    onClose
                )}
            </div>
        </Dialog>
    );
}

export default ConfirmationDialog;
