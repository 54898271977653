import React from 'react';
import { Checkbox, CheckboxProps, makeStyles } from '@material-ui/core';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
interface StyleProps {
    error?: boolean;
    padding?: string;
    uncheckedColor?: string;
    checkedColor?: string;
    checkedHoverColor?: string;
    errorColor?: string;
    disabledColor?: string;
    size?: number | string;
    cantEdit?: boolean;
}

const useCheckBoxStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            padding: props.padding,
            fontSize: props.size,
            color: props.error ? props.errorColor : props.uncheckedColor,
            '&.Mui-checked': {
                color: props.checkedColor,
            },
            '&.Mui-checked:hover': {
                backgroundColor: props.checkedHoverColor,
            },
            '&.Mui-disabled': {
                cursor: 'default',
                pointerEvents: 'all !important',
                color: props.cantEdit
                    ? props.disabledColor
                    : props.checkedColor,
            },
            '&:hover': {
                backgroundColor: props.cantEdit
                    ? 'transparent'
                    : props.checkedHoverColor,
            },
        },
    }));

interface ComponentProps
    extends StyleProps,
        Omit<CheckboxProps, 'size' | 'onChange'> {
    onChange?: (checked: boolean) => void;
    checked: boolean;
}

/**
 * Generic rounded checkbox
 */
function CustomCheckBox({
    padding = '9px',
    onChange,
    error = false,
    size = '1.5rem',
    checkedColor = '#3d5a7f',
    uncheckedColor = '#787878',
    disabledColor = '#A0A0A0',
    errorColor = '#cc2936',
    cantEdit = false,
    checked,
    ...others
}: ComponentProps): JSX.Element {
    const classes = useCheckBoxStyles({
        size,
        error,
        padding,
        cantEdit,
        errorColor,
        checkedColor,
        disabledColor,
        uncheckedColor,
        checkedHoverColor: checkedColor + '0a',
    })();

    return (
        <Checkbox
            {...others}
            disabled={cantEdit}
            classes={classes}
            checked={checked}
            onChange={(event) => onChange?.(event.target.checked)}
            required={true}
            icon={<CheckBoxOutlineBlankRoundedIcon fontSize="inherit" />}
            checkedIcon={<CheckBoxRoundedIcon fontSize="inherit" />}
        />
    );
}

/**
 * Generic datepicker with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedCheckBox extends React.Component<ComponentProps> {
    render(): JSX.Element {
        return <CustomCheckBox {...this.props} />;
    }
}
export default RoundedCheckBox;
