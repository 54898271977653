import { nanoid } from 'nanoid';
import { Form } from '../@Types';
import { FormStep, Mapper } from '../@Types/FormStep';
import FormStepTypes, {
    EntityValueOptionTypes,
    FormTypes,
} from '../constants/FormStepTypes';
import { getRawText, stringToDraft } from '../Utils/DraftFunctions';
import { SiteState, ValuesStore } from '../States/SiteSlice';
import { CustomStep } from '../FormSteps/CustomStep';
import { MapperElement } from '../@Types/MapperElement';
import { Condition } from '../@Types/Condition';
import ConditionTypes from '../constants/ConditionTypes';
import widgetInstance from '../Utils/AxiosWidget';
import { MapperValue } from '../FormSteps/MapperStep/MaterialMapperStep/MaterialMapperStep';
import { calcRecursiveData } from '../Utils/FormStepFunctions';

export const calcValuesStore = async (
    idOrganization: string,
    form: Readonly<Form>,
    originalValues: Record<string, any> = {},
    postview: boolean = false,
    customSteps: Record<string, CustomStep> = {}
): Promise<ValuesStore> => {
    const values: ValuesStore = {
        global: {},
        sections: {},
    };

    await Promise.all(
        Object.keys(originalValues).map(async (idValue) => {
            const step = form.steps[idValue];
            if (step) {
                const value = await mapOriginalValue(
                    idOrganization,
                    step,
                    originalValues[step.id],
                    values,
                    form
                );
                if (value !== undefined) {
                    if (!form.hiddenSteps?.includes(step.id)) {
                        if (!values.sections[step.idSection])
                            values.sections[step.idSection] = {};
                        values.sections[step.idSection][step.id] = value;
                    } else {
                        values.global[idValue] = value;
                    }
                }
            } else {
                values.global[idValue] = originalValues[idValue];
            }
        })
    );

    if (postview) return values;
    for (const section of Object.values(form.sections)) {
        for (const idStep of section.steps) {
            const step = form.steps[idStep];
            if (
                step.type !== FormStepTypes.MAPPER ||
                values.sections[step.idSection]?.[step.id]
            )
                continue;
            if (!values.sections[step.idSection])
                values.sections[step.idSection] = {};
            const { element, mappers } = addMapperStep(step, customSteps);
            values.sections[step.idSection] = {
                ...values.sections[step.idSection],
                ...mappers,
                [step.id]: { elements: [element], page: 0 },
            };
        }
    }
    return values;
};

export const addMapperStep = <Type>(
    step: Mapper,
    customSteps: Record<string, CustomStep>,
    path: string = ''
): {
    element: MapperElement<Type>;
    /** Record of all the new mapper values created */
    mappers: Record<string, MapperValue<Type>>;
    /** Record of all the new steps created */
    steps: Record<string, FormStep>;
} => {
    const idElement = nanoid();
    const element: MapperElement<Type> = {
        ids: {},
        id: idElement,
        originalValues: {},
        isOriginal: false,
    };
    let steps: Record<string, FormStep> = {};
    let mappers: Record<string, MapperValue<Type>> = {};

    const base = path ? path + '-' : '';
    for (const idStep of Object.keys(step.steps)) {
        const baseStep = JSON.parse(JSON.stringify(step.steps[idStep]));
        const newIdStep = base + step.id + '-' + idElement + '-' + idStep;
        baseStep.id = newIdStep;
        element.ids[idStep] = newIdStep;
        steps[newIdStep] = baseStep;
    }
    calcRecursiveData(element, steps, customSteps);
    for (const nestedStep of Object.values(step.steps)) {
        if (nestedStep.type === FormStepTypes.MAPPER) {
            const nested = addMapperStep<Type>(
                nestedStep,
                customSteps,
                base + step.id + '-' + idElement
            );
            steps = { ...steps, ...nested.steps };
            mappers = { ...mappers, ...nested.mappers };
            mappers[element.ids[nestedStep.id]] = {
                elements: [nested.element],
                page: 0,
            };
        }
    }
    return { element, mappers, steps };
};

export const mapOriginalValue = async (
    idOrganization: string,
    step: FormStep,
    value: any,
    values: ValuesStore,
    form?: Readonly<Form>,
    path: string = ''
): Promise<any> => {
    if (!value) return value;

    switch (step?.type) {
        case FormStepTypes.MAPPER: {
            const elements = value;
            const mappedElements: MapperElement<any>[] = [];
            for (const element of elements) {
                const idElement = element.id ?? nanoid();
                const mappedElement: MapperElement<any> = {
                    id: idElement,
                    ids: {},
                    originalValues: {},
                    isOriginal: true,
                };
                if (element.value) {
                    mappedElement.value = element.value;
                }
                const base = path ? path + '-' : '';
                await Promise.all(
                    Object.keys(step.steps).map(async (idStep) => {
                        const newIdStep =
                            base + step.id + '-' + idElement + '-' + idStep;
                        mappedElement.ids[idStep] = newIdStep;
                        const value = await mapOriginalValue(
                            idOrganization,
                            step.steps[idStep],
                            element[idStep],
                            values,
                            form,
                            base + step.id + '-' + idElement
                        );
                        if (value !== undefined) {
                            if (!values.sections[step.idSection])
                                values.sections[step.idSection] = {};
                            values.sections[step.idSection][newIdStep] = value;
                        }
                    })
                );

                for (const subStep of Object.values(step.steps)) {
                    let dependencies = subStep.dependencies ?? [];
                    if (subStep.condition) {
                        dependencies = [
                            ...dependencies,
                            ...calcNestedConditionSteps(subStep.condition),
                        ].filter((item, i, ar) => ar.indexOf(item) === i);
                    }
                    for (const idDep of dependencies) {
                        const mappedIdDep =
                            base + step.id + '-' + idElement + '-' + idDep;
                        if (
                            !values.sections[step.idSection][idDep] &&
                            !values.global[mappedIdDep] &&
                            element[idDep]
                        ) {
                            values.global[mappedIdDep] = element[idDep];
                            mappedElement.ids[idDep] = mappedIdDep;
                        }
                    }
                }
                for (const key of Object.keys(element)) {
                    if (step.steps[key] || key === 'id') continue;
                    mappedElement.originalValues[key] = element[key];
                }
                mappedElements.push(mappedElement);
            }
            return { elements: mappedElements, page: 0 };
        }
        case FormStepTypes.TEXTAREA: {
            if (step.hasTextEditor) {
                if (typeof value === 'string') {
                    return stringToDraft(value);
                }
                return getRawText(value?.draft, value?.text);
            }
            const defaultValue = value.value ?? value;
            if (typeof defaultValue === 'string') {
                return defaultValue;
            } else {
                return defaultValue?.text ?? '';
            }
        }
        case FormStepTypes.SELECTOR: {
            const option = step.options.find(
                (option) => option.value === value
            );
            if (!value?.value) return option;
            return value;
        }
        case FormStepTypes.CLASSIFIER_SELECTOR: {
            const stepClassifier = form?.classifiers?.[step.idClassifier ?? ''];
            if (stepClassifier && !value.value) {
                const classifier = form.classifiers?.[value];
                return { value, label: classifier?.name };
            }
            break;
        }
        case FormStepTypes.ENTITYVALUEPICKER: {
            if (!value._id) {
                const params = new URLSearchParams(value);
                const url = `${idOrganization}/entities/${
                    step.idEntity
                }?${params.toString()}`;
                const entityValues = (await widgetInstance.get(url)).data;
                if (entityValues.length === 1) {
                    const entityValue = entityValues[0];
                    if (
                        step.options[entityValue._id]?.type ===
                        EntityValueOptionTypes.HIDE
                    )
                        return undefined;
                    return entityValues[0];
                }

                return null;
            }
            return value;
        }
        default:
            if (
                step.type.startsWith('CBR_') &&
                value?.id &&
                typeof value.id === 'number'
            ) {
                return { ...value, id: value.id.toString() };
            }
            return value;
    }
};

function calcNestedConditionSteps(condition: Condition): string[] {
    const steps: string[] = [];
    if (!condition) return steps;
    switch (condition.type) {
        case ConditionTypes.EXPRESSION:
            return condition.conditions
                .map((condition) => calcNestedConditionSteps(condition))
                .flat();
        case ConditionTypes.FORM_STEP:
            return [condition.idStep];
        default:
            break;
    }
    return steps;
}

export const calcInitialSections = (
    form: Form
): Pick<
    SiteState,
    'previousSections' | 'idCurrentSection' | 'nextSections'
> => {
    const previousSections: string[] = [];
    const nextSections: string[] = calcNextSections(form.firstSection, form);
    let idCurrentSection: string | null = null;
    if (form.type === FormTypes.STEPPER) {
        const firstSection = nextSections.shift();
        if (firstSection) idCurrentSection = firstSection;
    }

    return { previousSections, idCurrentSection, nextSections };
};

const calcNextSections = (idSection: string, form: Form): string[] => {
    const section = form.sections[idSection];
    if (!section) return [];
    const sections = [idSection];
    if (section.nextSection)
        sections.push(...calcNextSections(section.nextSection, form));
    return sections;
};
