import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import {
    ClickAwayListener,
    FormHelperText,
    MenuItem,
    Popper,
} from '@material-ui/core';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { SelectorStyleProps } from '../RoundedSelect/RoundedSelect';
import styles from './RoundedTimePicker.module.css';
import { Time } from '../../@Types/Time';
import RoundedTextField from '../RoundedTextField/RoundedTextField';
import Toggle from '../Toggle/Toggle';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useOutlinedInputStyles = (
    props: SelectorStyleProps
): (() => ClassNameMap) =>
    makeStyles({
        root: {
            cursor: props.cantEdit ? 'default' : 'default',
            height: props.height,
            borderRadius: props.borderRadius,
            backgroundColor: props.backgroundColor,
            fontSize: props.fontSize,
            '& $notchedOutline': {
                borderColor: props.outlineColor,
                '& legend': {
                    fontSize: 'calc(' + props.fontSize + ' * 0.75)',
                },
            },
            '&:hover $notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .Mui-disabled': {
                cursor: 'default',
                color: props.color,
                pointerEvents: 'none',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&.Mui-disabled .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '& .EF-MuiSelect-root': {
                paddingLeft: props.paddingLeft,
                paddingRight: props.paddingRight,
            },
            '&$focused $notchedOutline': {
                borderColor: props.focusColor,
            },
            '& .EF-MuiSelect-icon': {
                color: props.color?.startsWith('#')
                    ? props.color + '8a'
                    : props.color,
            },
            '& .EF-MuiSelect-icon.Mui-disabled': {
                color: props.outlineColor,
            },
            '& .EF-MuiSelect-icon.iconOutlined': {
                right: 8,
            },
            '& .EF-MuiSelect-iconOpen': {
                transform: props.showIcon ? 'none' : 'rotate(180deg)',
            },
            color: props.color,
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = (props: SelectorStyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        focused: {
            color: props.focusColor + ' !important',
        },
        root: {
            color: props.color?.startsWith('#')
                ? props.color + '8a'
                : props.color,
            fontWeight: props.fontWeight,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 'calc(100% - 45px)',
            textOverflow: 'ellipsis',
            marginTop: props.height != '40px' ? '-4px' : '0px',
            '&.EF-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useHelperTextStyles = (props: SelectorStyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            color: props.helperTextColor,
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useMenuStyles = (): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            '& .EF-MuiPaper-root': {
                opacity: '0 !important',
            },
        },
    }));

interface RoundedTimePickerProps
    extends Omit<SelectProps, 'color' | 'onChange'>,
        SelectorStyleProps {
    /** Currently selected value */
    value: Time | undefined;
    /** function called when value changes */
    onChange?: (time: Time) => void;
    /** Strig to place in the label */
    label: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** The helper Text to display */
    helperText?: string | null;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** If outline should be error color */
    highlightError?: boolean;
    /** the margin around the selector */
    containerMargin?: string;
    /** The icon to display */
    iconComponent?: any;
    pickDays?: boolean;
    minDays?: number;
    maxDays?: number;
    pickHours?: boolean;
    minHours?: number;
    maxHours?: number;
    pickMinutes?: boolean;
    minMinutes?: number;
    maxMinutes?: number;
    working?: boolean;
}

function CustomSelect({
    value,
    onChange,
    label,
    minWidth,
    helperText,
    color = '#293241',
    errorColor = '#cc2936',
    focusColor = '#3d5a7f',
    outlineColor = '#0000003b',
    helperTextColor = '#989898',
    backgroundColor = '#ffffff',
    readOnly = false,
    borderRadius = 10,
    paddingLeft = 14,
    paddingRight = 32,
    cantEdit = false,
    containerMargin = '8px',
    height = '40px',
    fontSize = '1rem',
    required,
    error,
    pickDays,
    minDays = 0,
    maxDays,
    pickHours,
    minHours = 0,
    maxHours,
    pickMinutes,
    minMinutes = 0,
    maxMinutes,
    working,
    ...others
}: RoundedTimePickerProps): JSX.Element {
    const StyleProps: SelectorStyleProps = {
        color,
        height,
        readOnly,
        fontSize,
        cantEdit,
        errorColor,
        focusColor,
        paddingLeft,
        paddingRight,
        outlineColor,
        borderRadius,
        helperTextColor,
        backgroundColor,
        showIcon: true,
    };
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles(StyleProps)();
    const labelClasses = useLabelInputStyles(StyleProps)();
    const helperTextClasses = useHelperTextStyles(StyleProps)();
    const menuClasses = useMenuStyles()();
    const [open, setOpen] = useState(false);
    const ref = React.useRef<HTMLInputElement>(null);
    const handleChange = useCallback((value: Time) => {
        const time = { ...value };
        if (time.days === 0) {
            delete time.days;
        }
        if (value.hours === 0) {
            delete time.hours;
        }
        if (time.minutes === 0) {
            delete time.minutes;
        }
        if (working !== undefined) {
            time.working = working;
        }
        onChange?.(time);
    }, []);

    const calcLabel = (): string => {
        const parts = [label];
        if (working === true) parts.push('(Hábil)');
        if (required) parts.push('*');
        return parts.join(' ');
    };
    return (
        <React.Fragment>
            {open && (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => setOpen(false)}
                >
                    <Popper
                        open={true}
                        anchorEl={ref.current}
                        placement={'bottom-start'}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['top', 'bottom', 'left', 'right'],
                                boundariesElement: 'window',
                                padding: 20,
                            },
                        }}
                        style={{ zIndex: 1301 }}
                    >
                        <div
                            className={styles.container}
                            style={{
                                paddingTop:
                                    error && helperText ? '0px' : '30px',
                            }}
                        >
                            {error && helperText && (
                                <div
                                    className={styles.errorMessage}
                                    style={{
                                        color: errorColor,
                                    }}
                                >
                                    {helperText}
                                </div>
                            )}
                            {pickDays && (
                                <div className={styles.numberPickerContainer}>
                                    <RoundedTextField
                                        label="Días"
                                        type="number"
                                        inputProps={{
                                            min: minDays,
                                            max: maxDays,
                                        }}
                                        value={(value?.days ?? minDays) + ''}
                                        onChange={(e): void => {
                                            handleChange({
                                                ...value,
                                                days: parseInt(e.target.value),
                                            });
                                        }}
                                    />
                                </div>
                            )}
                            {pickHours && (
                                <div className={styles.numberPickerContainer}>
                                    <RoundedTextField
                                        label="Horas"
                                        type="number"
                                        inputProps={{
                                            min: minHours,
                                            max: maxHours,
                                        }}
                                        value={(value?.hours ?? minHours) + ''}
                                        onChange={(e): void => {
                                            handleChange({
                                                ...value,
                                                hours: parseInt(e.target.value),
                                            });
                                        }}
                                    />
                                </div>
                            )}
                            {pickMinutes && (
                                <div className={styles.numberPickerContainer}>
                                    <RoundedTextField
                                        label="Minutos"
                                        type="number"
                                        inputProps={{
                                            min: minMinutes,
                                            max: maxMinutes,
                                        }}
                                        value={
                                            (value?.minutes ?? minMinutes) + ''
                                        }
                                        onChange={(e): void => {
                                            handleChange({
                                                ...value,
                                                minutes: parseInt(
                                                    e.target.value
                                                ),
                                            });
                                        }}
                                    />
                                </div>
                            )}
                            {working === undefined && (
                                <div className={styles.workingContainer}>
                                    Hábiles:
                                    <Toggle
                                        checked={!!value?.working}
                                        disabled={working !== undefined}
                                        onChange={(working: boolean): void => {
                                            handleChange({
                                                ...value,
                                                working,
                                            });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </Popper>
                </ClickAwayListener>
            )}
            <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
                style={
                    minWidth !== undefined
                        ? { minWidth, outlineColor, margin: containerMargin }
                        : {
                              margin: containerMargin,
                          }
                }
                fullWidth
                required={required}
                error={error}
            >
                {label && (
                    <InputLabel classes={labelClasses}>{label}</InputLabel>
                )}
                <Select
                    {...others}
                    innerRef={ref}
                    title={calcTimeString(value)}
                    value={value ? 0 : ''}
                    IconComponent={ScheduleRoundedIcon}
                    onClose={(): void => {
                        if (!cantEdit) setOpen(false);
                    }}
                    onOpen={(): void => {
                        if (cantEdit) return;
                        setOpen(true);
                        if (required) handleChange(value ?? {});
                    }}
                    onFocus={(e): void => {
                        if (
                            !e.relatedTarget?.closest('.EF-MuiPaper-root') &&
                            !cantEdit
                        ) {
                            setOpen(true);
                        }
                    }}
                    onBlur={(): void => {
                        if (!cantEdit) setOpen(false);
                    }}
                    input={
                        <OutlinedInput
                            disabled={cantEdit}
                            name={label}
                            label={label ? calcLabel() : undefined}
                            classes={outlinedInputClasses}
                        />
                    }
                    renderValue={(): undefined | React.ReactNode =>
                        calcTimeString(value)
                    }
                    MenuProps={{
                        PopoverClasses: menuClasses,
                    }}
                >
                    <MenuItem value={0}></MenuItem>
                </Select>
                {helperText !== undefined && (
                    <FormHelperText classes={helperTextClasses}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </React.Fragment>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedTimePicker extends React.Component<RoundedTimePickerProps> {
    render(): JSX.Element {
        return <CustomSelect {...this.props} />;
    }
}
export default RoundedTimePicker;

function calcTimeString(value: Time | undefined): string | undefined {
    if (!value) return undefined;
    const parts = [];
    if (value.days) {
        parts.push(`${value.days} Día${value.days > 1 ? 's' : ''}`);
    }
    if (value.hours) {
        parts.push(`${value.hours} Hora${value.hours > 1 ? 's' : ''}`);
    }
    if (value.minutes) {
        parts.push(`${value.minutes} Minuto${value.minutes > 1 ? 's' : ''}`);
    }
    if (parts.length === 0)
        return value.working ? 'Horario Hábil Más Cercano' : 'Inmediatamente';
    if (value.working) {
        const isMultiple = parts.length > 1 || parts[0].includes('s');
        return parts.join(', ') + (isMultiple ? ' Hábiles' : ' Hábil');
    }
    return parts.join(', ');
}
